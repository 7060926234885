<template>
  <transition name="menu">
    <form-surat :is-edit="true"/>
  </transition>
</template>

<script>
import formSurat from '../components/formSurat5'

export default {
  name: 'EditformSurat5',
  components: { formSurat }
}
</script>

